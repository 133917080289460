import React from 'react';
import { Link } from 'gatsby';

import { getPath } from '../utils/paths';
import Section404 from '../components/section-404';
import PageStatic from '../components/page-static';

function NotFoundPage(props) {
  const TITLE = 'Page not found.';
  return (
    <PageStatic {...props} seoTitle={TITLE}>
      <Section404 title={TITLE}>
        <>
          Have questions? Head over to the{' '}
          <Link to={getPath.support()}>Support page</Link>, visit our{' '}
          <Link to={getPath.downloads()}>Downloads</Link> for free resources, or
          go back to the <Link to={getPath.home()}>Homepage</Link>.
        </>
      </Section404>
    </PageStatic>
  );
}

export default NotFoundPage;
